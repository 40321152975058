import { Component, OnInit } from '@angular/core';
import { CommonServiceLayerService } from 'src/app/services/common-service-layer.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SessionhandlerService } from 'src/app/services/sessionhandler.service';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { Config } from 'src/app/config/config';
import { AlertService } from 'src/app/shared/alert.service';

@Component({
  selector: 'app-manual-complaints',
  templateUrl: './manual-complaints.component.html',
  styleUrls: ['./manual-complaints.component.scss']
})
export class ManualComplaintsComponent implements OnInit {
  public metaData: any;
  public state;
  public complaintDetails = {
    state: '',
    image: [],
    document: []
  };
  public parentData: any = { error_cls: 'advanced_form_error', formSubmitted: false, noDynamicFieldWidth: true };
  public loginLogo = { logo: '', title: '' };

  constructor(private http: CommonServiceLayerService,
    private sharedService: SharedService,
    private session: SessionhandlerService,
    private router: Router,
    public appComponent: AppComponent,
    public alert: AlertService
  ) { }

  // public logo: any = this.appComponent.getHeaderData().logo;
  // public title: any = this.appComponent.getHeaderData().title;
  ngOnInit() {
    if (['jammukashmir-ccr.glensserver.com'].includes(window.location.hostname)) {
      this.state = "J&K";
    }
    if (['puducherry-ccr.glensserver.com','192.168.0.210'].includes(window.location.hostname)) {
      this.state = "Puducherry";
    }
    if(['telangana-ccr.glensserver.com', 'localhost'].includes(window.location.hostname)){
      this.state = "Telangana";
    }
    // this.state = this.session.api.local.get('state');
    this.complaintDetails.state = this.state;
    this.getStateLogo();
    this.getFormData();
  }
  getStateLogo() {
    try {
      this.http.others.getStateMetaData().subscribe(
        response => {
          this.loginLogo = response[window.location.hostname] ? response[window.location.hostname] : response['default'];
          return this.loginLogo;
        });
    } catch (error) {
      Config.logger(error);
    }
  }
  getFormData() {
    // this.openModal = false;
    try {
      const payLoad = {
        state: this.state,
      };
      this.parentData.formSubmitted = false;
      this.http.ComplaintsModule.complaintEntryUploadFields(payLoad).subscribe(
        response => {
          if (response && response.status) {
            this.metaData = response.data;
            // this.getDropDownData();
            // this.commentPayLoad = response.formData;
          } else {
            this.sharedService.serviceErrorMessage(response.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  getDropDownData() {
    // this.openModal = false;
    try {
      const payLoad = {
        loadType: 'manualComplaint',
      };
      this.http.ComplaintsModule.complaintEntryDropDownData(payLoad).subscribe(
        response => {
          if (response && response.status) {
            // this.metaData.complaintDetails.complaintCategory
          } else {
            this.sharedService.serviceErrorMessage(response.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  submit() {
    console.log(this.complaintDetails['image']);
    this.parentData.formSubmitted = true;
    if (this.sharedService.getDisabledStatus(this.parentData.error_cls)) {
      return true;
    }
    if (this.countWords(this.complaintDetails['description'])) {
      this.alert.open('info', 'Description has more than 100 words', 'Info');
      return true;
    }
    try {
      const payload = this.complaintDetails;
      this.http.ComplaintsModule.submitManualComplaint(payload).subscribe(
        response => {
          if (response && response.status) {
            this.sharedService.serviceSuccessMessage(response.message);
            this.goToLogin();
            // this.metaData.complaintDetails.complaintCategory
            console.log(response);
          } else {
            this.sharedService.serviceErrorMessage(response.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
    console.log(this.complaintDetails)
  }
  clear() {
    this.complaintDetails = {
      state: this.state,
      image: [],
      document: []
    }
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
  countWords(data) {
    try {
      data = data.trim();
      let words = data.split(' ');
      if (words.length > 100) {
        return true;
        // words.splice(words.length - 1, 1);
        // this.complaintDetails['description'] = words.join(' ').trim();
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  }
}
