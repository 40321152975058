import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
const jqeury = $ as any;

@Component({
  selector: 'app-vertical-sliding-params',
  templateUrl: './vertical-sliding-params.component.html',
  styleUrls: ['./vertical-sliding-params.component.scss']
})
export class VerticalSlidingParamsComponent implements OnInit {

  @Input() stationList;
  @Output() action: EventEmitter<any>;

  constructor() {
    this.action = new EventEmitter();
  }

  public monitoringMap = {
    Emission: 'fa-filter',
    Effluent: 'fa-tint',
    Ambient: 'fa-heart'
  };

  ngOnInit() {
    this.addExceedanceClass();
    setTimeout(() => {
      this.initializeMarqueeVertical();
    }, 1000);
  }
  addExceedanceClass() {
    this.stationList.forEach(station => {
      station.parameters.forEach(param => {
        if (param.value > param.limit) {
          station.exceedance = true;
          return true;
        }
      });
    });
  }
  initializeMarqueeVertical() {
    jqeury('.vertical-sliding-marquee').marquee({
      speed: 70,
      startVisible: true,
      pauseOnHover: true,
      direction: 'up'
    });
  }

  parentAction(action, data) {
    this.action.emit({ action, data });
  }

}
