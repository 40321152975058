import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import { LoaderService } from '../shared/loader/loader-service.service';
import { SessionhandlerService } from '../services/sessionhandler.service';
import { Config } from '../config/config';
import { AlertService } from '../shared/alert.service';

@Injectable({
    providedIn: 'root'
})
export class HttpLayerService {
    private monitoring = {
        pendingRequestsNumber: 0
    };
    public currentDomain: any = window.location.hostname;
    public domain: any;
    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    public isLogout = false;
    constructor(
        private http: HttpClient,
        private router: Router,
        private loaderService: LoaderService,
        private sessionService: SessionhandlerService,
        private alert: AlertService,
        private route: ActivatedRoute,
    ) {
        this.domain = (this.currentDomain === 'localhost') ? 'ospcb.glensserver.com' : this.currentDomain;
    }

    get(url: string, hideLoader?: any, disableTokenValidation?: boolean): Observable<any> {
        try {
            if (!hideLoader) {
                this.showLoader();
                this.monitoring.pendingRequestsNumber++;
            }
            let headerJson;
            const token = this.sessionService.api.local.get('userName');
            if (!token && !disableTokenValidation) {
                this.logOut();
            }
            headerJson = {
                'Content-Type': 'text/plain',
                authorization: `bearer ${token}`
            };

            const headersConfig = new HttpHeaders(headerJson);
            const requestOptions: any = { headersConfig, responseType: 'text' };
            return this.handleResponse(this.http.get(url, requestOptions)).map((res: any) => {
                let response = res;
                try {
                    response = atob(res);
                } catch (error) {
                } finally {
                    response = JSON.parse(response);
                }
                return response;
            }).finally(() => {
                if (!hideLoader) {
                    this.monitoring.pendingRequestsNumber--;
                    this.hideLoader();
                }
            });
        } catch (error) {
            Config.logger(error);
        }
    }

    post(url: string, data: any, hideLoader?, options?: any): Observable<any> {
        try {
            if (!hideLoader) {
                this.showLoader();
                this.monitoring.pendingRequestsNumber++;
            }
            let headerJson;
            const token = this.sessionService.api.local.get('userName');
            if (!token) {
                this.logOut();
            }
            headerJson = options ? options : {
                'Content-Type': 'application/json',
                authorization: `bearer ${token}`
            };
            const headersConfig = new HttpHeaders(headerJson);
            const requestOptions = { headers: headersConfig };
            this.monitoring.pendingRequestsNumber++;
            const userDetails = this.getUserDetails();
            data = Object.assign(userDetails, data);
            const body = JSON.stringify(data);
            return this.handleResponse(this.http.post(url, body, requestOptions)).finally(() => {
                if (!hideLoader) {
                    this.monitoring.pendingRequestsNumber--;
                    this.hideLoader();
                }
            });
        } catch (error) {
            Config.logger(error);
        }
    }

    postText(url: string, data: any = {}, hideLoader?, options?: any, decode?: boolean, disableTokenValidation?: boolean): Observable<any> {
        try {
            if (!hideLoader) {
                this.showLoader();
                this.monitoring.pendingRequestsNumber++;
            }
            let headerJson;
            const token = this.sessionService.api.local.get('userName');
            if (!token && !disableTokenValidation && !url.includes('feedback') && !url.includes('forgotPassword') && !url.includes('formJson') && !url.includes('parameterTrend') && !url.includes('csv') && !url.includes('excel') && !url.includes('html') && !url.includes('geoJson') && !url.includes('publicDropDownData') && !url.includes('publicAQIMapData') && !url.includes('publicAQIChartData') && !url.includes('fetchComplaint')) {
                this.logOut();
            }
            headerJson = options ? options : {
                'Content-Type': 'text/plain',
                authorization: `bearer ${token}`
            };

            const headersConfig = new HttpHeaders(headerJson);
            const requestOptions: any = { headersConfig, responseType: 'text' };
            const userDetails = this.getUserDetails();
            data = Object.assign(userDetails, data);
            const body = window.btoa(JSON.stringify(data));
            return this.handleResponse(
                this.http.post(url, body, requestOptions).map((res: any) => {
                    let response = res;
                    if (!decode) { response = window.atob(res); }
                    response = JSON.parse(response);
                    return response;
                })
            ).finally(() => {
                if (!hideLoader) {
                    this.monitoring.pendingRequestsNumber--;
                    this.hideLoader();
                }
            });
        } catch (error) {
            Config.logger(error);
        }
    }
    getUserDetails() {
        try {
            return {
                userName: this.sessionService.api.local.get('userName'),
                userRole: this.sessionService.api.local.get('userRole'),
                userId: this.sessionService.api.local.get('userId'),
                // station_list: this.sessionService.api.local.get('station_list'),
                // siteId: this.sessionService.api.local.get('siteId'),
                // siteName: this.sessionService.api.local.get('siteName'),
                state: this.sessionService.api.local.get('state'),
                district: this.sessionService.api.local.get('district') ? this.sessionService.api.local.get('district') : undefined,
            };
        } catch (error) {
            Config.logger(error);
            return {};
        }
    }
    postFile(url: string, formData: FormData, data: any, hideLoader: boolean = false): Observable<any> {
        /**
         * With header 'Content-Type': 'multipart/form-data' file was not getting in BE
         */
        const headerOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        };
        try {
            if (!hideLoader) {
                this.showLoader();
                this.monitoring.pendingRequestsNumber++;
            }
            const userDetails = this.getUserDetails();
            data = Object.assign(userDetails, data);
            formData.append('jsonEnd', JSON.stringify(data));
            return this.handleResponse(this.http.post(url, formData, headerOptions)).finally(() => {
                if (!hideLoader) {
                    this.monitoring.pendingRequestsNumber--;
                    this.hideLoader();
                }
            });
        } catch (error) {
            Config.logger(error);
            throw 500;
        }
    }


    handleResponse(observable: Observable<any>, isBackgroundServiceCall: boolean = false): Observable<any> {
        try {
            return observable.catch((err, source) => {
                if (err.status === 401 || err.status === 403) {
                    this.alert.open('info', 'Acess Denied', err.statusText);
                    if (this.isLogout) {
                        this.isLogout = false;
                    } else {
                        this.logOut();
                    }
                } else {
                    this.alert.open('error', 'Error', 'Service is unavailable');
                }
                return Observable.throw(err);
            }).finally(() => {
                if (this.monitoring.pendingRequestsNumber === 0) {
                    this.hideLoader();
                }
            });
        } catch (e) {
            Config.logger(e);
        }
    }
    logOut() {
        this.sessionService.api.local.clear();
        this.sessionService.api.session.clear();
        this.isLogout = true;
        try {
            this.postText(Config.SERVICE_IDENTIFIER.logout, {}, true, undefined, undefined, true).subscribe(data => {
                console.log(data);
            });
        } catch (error) {
            Config.logger(error);
        }

        this.router.navigate(['/login']);
    }
    public handleError(error: any) {
        const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg);
        return Observable.throw(errMsg);
    }
    private showLoader(): void {
        this.loaderService.show();
    }

    private hideLoader(): void {
        if (this.monitoring.pendingRequestsNumber === 0) {
            this.loaderService.hide();
        }
    }
}
