import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface ImageViewer {
  url: string;
  type: string;
}

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss']
})

export class MediaViewerComponent implements OnInit {

  @Input() data: ImageViewer;
  @Output() action: EventEmitter<any>;

  constructor() {
    this.action = new EventEmitter();
  }

  ngOnInit() {
    document.getElementById('mediaViewerModal').click();
  }

  closeModal() {
    try {
      this.action.emit({ type: 'close' });
    } catch (error) {
      console.log(error);
    }
  }
}
