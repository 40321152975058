import { Component, OnInit, Input } from '@angular/core';
import { CommonServiceLayerService } from '../../services/common-service-layer.service';
import { AlertService } from '../../shared/alert.service';
import { SessionhandlerService } from '../../services/sessionhandler.service';
import { SharedService } from '../shared.service';
import { Config } from '../../config/config';
import { domToImage } from 'dom-to-image';
import { JqueryUtilitiesService } from '../utilities/jquery-utilities.service';
import { LayoutService } from '../../layout/layout.service';
import { AppComponent } from 'src/app/app.component';
declare var require: any;

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.scss']
})
export class PrinterComponent implements OnInit {
  @Input() tableData;
  @Input() id;
  @Input() buttons;
  @Input() printDivInfo;
  @Input() metaData;
  @Input() hideButtonLabels;
  buttonInfo = {
    print: { icon: 'fa-file-o', title: 'Print' },
    excel: { icon: 'fa-file-excel-o', title: 'Excel' },
    pdf: { icon: 'fa-file-pdf-o', title: 'PDF' },
    csv: { icon: 'fa fa-file', title: 'CSV' },
    word: { icon: 'fa fa-file-word-o', title: 'Word' },
    html: { icon: 'fa fa-file-code-o', title: 'HTML' },
    image: {
      icon: '', title: 'Image',
      types: [{ value: 'png', label: 'PNG' }, { value: 'jpeg', label: 'JPG' }
      // ,{ value: 'excel', label: 'Excel' }
      ]
    }
  };
  public tableExcelData: any;
  public encodedImage: any;
  public filtersData: any = {};
  public fields: any;
  public imageLoader = true;
  public GLensLogo: any = Config.GLENS_IMAGE;
  public FujerahLogo: any = Config.FUJERAH_IMAGE;
  constructor(
    public http: CommonServiceLayerService,
    public alert: AlertService,
    public sessionService: SessionhandlerService,
    public shared: SharedService,
    public jQueryUtility: JqueryUtilitiesService,
    public layoutService: LayoutService,
    public appComponent: AppComponent
  ) { }
  public stateLogo: any = this.appComponent.getHeaderData().logo;
  ngOnInit() {
    // Config.logger(this.tableData);
    this.getAppliedFiltersData();
    this.metaData = Object.assign(this.metaData || {});
  }
  triggerEvent(type) {
    if (this.metaData.multipleHeaders || this.id) {
      this.exportTable(type);
    } else {
      this[type]();
    }
  }
  printImage(img) {
    this.imageLoader = false;
    const DTI = require('dom-to-image');
    const date = this.shared.formatFromAndToDates(this.filtersData.date);
    const imageMapping = { png: 'toPng', jpeg: 'toJpeg' };
    const imageDivHeader = `
    <div class = "col-md-12 row" style="border-bottom: 1px solid #000;">
        <div class= "col-md-2">
        </div>
        <div class="col-md-9" style = "text-align: center;font-size:18px;">
           <h5> Report Name : ${this.metaData.reportName ? this.metaData.reportName : ''} </h5>
           <div> Station Name : ${this.filtersData.station ? this.filtersData.station : ''}</div>
           <div>From Date: ${ date.fromDate ? date.fromDate : ''}, To Date: ${date.toDate ? date.toDate : ''} </div>
    </div>
    <div class= "col-md-1" >
    <img src="assets/images/GreenLens_black.png" style = "width: 7em;" >
      </div>
        </div>`;
    const id: any = document.getElementById(this.printDivInfo.imageId);
    let imageDuplicate;
    if (this.printDivInfo.echart) {
      imageDuplicate = this.cloneCanvas(id.firstChild.firstElementChild);
    } else {
      imageDuplicate = id.cloneNode(true);
    }
    document.getElementById('downloadImageDiv').appendChild(imageDuplicate);
    document.getElementById('imageHeaderDiv').innerHTML = imageDivHeader;
    if (img.value === 'excel') {
      DTI.toPng(document.getElementById('headerDownloadImageDiv'), { quality: 0.95 })
        .then(dataUrl => {
          this.createImageExcel(dataUrl);
        });
    } else {
      DTI[imageMapping[img.value]](document.getElementById('headerDownloadImageDiv'), { quality: 0.95 })
        .then(dataUrl => {
          this.createImage(dataUrl, img.value);
        });
    }
  }
  createImage(dataUrl, extension) {
    const link = document.createElement('a');
    link.download = this.printDivInfo.imageName + `.${extension}`;
    link.href = dataUrl;
    link.click();
    this.imageLoader = true;
    document.getElementById('downloadImageDiv').innerHTML = '';
  }
  createImageExcel(dataUrl) {
    dataUrl = dataUrl.split(',');
    if (this.tableData) {
      this.tableExcelData = this.getPayLoad();
    } else {
      this.tableExcelData = {
        dataExcel: '.*.*.*.*.*.*.',
        headerContent: {
          ReportName: this.metaData.reportName,
          ReportType: this.metaData.reportName
        },
        dataDelimiter: '*'
      };
    }
    this.tableExcelData.imageUpload = dataUrl[1];
    try {
      this.imageLoader = true;
      document.getElementById('downloadImageDiv').innerHTML = '';
      this.http.printerServices.ExcelDump(this.tableExcelData).subscribe(
        response => {
          try {
            if (response && response.name) {
              window.open(Config.SERVICE_IDENTIFIER.download + '?name=' + response.name);
            } else {
              this.alert.open('error', 'Failed to download, please try again', 'Failed');
            }
          } catch (error) {
            this.shared.dataNotFoundMsg(error);
            Config.logger(error);
          }
        }
      );
    } catch (error) {
      Config.logger(error);
      this.shared.internalCodeError(error);
    }
  }
  print() {
    // Create document with current html head and set metadata, then open the doc in new tab and print the same
    const id: any = document.getElementById(this.printDivInfo.id);
    const date = this.shared.formatFromAndToDates(this.filtersData.date);
    let divContent;
    if (this.printDivInfo.canvas) {
      divContent = `<img src = "${id.toDataURL()}"/>`;
    } else if (this.printDivInfo.echart) {
      divContent = `<img src = "${id.firstChild.firstElementChild.toDataURL()}"/>`;
    } else {
      divContent = id.innerHTML;
    }
    const template = `
    <!DOCTYPE html>
      <html>
        <head>
          <title>${this.metaData.reportName}</title>
          <style>
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10{width:83%; float:left;}
          .col-print-11{width:92%; float:left;}
          .col-print-12{width:100%; float:left;}
          .center-text{
            text-align: center;
          }
          .printHeaderCSS{
            border-bottom: 1px solid #000;
          }
          .input-container{
            display: none;
          }
          app-printer {
            display: none;
          }
          .c3 path, .c3 line {
            fill: none;
            stroke: #000; }
            .chart-printer-logo{
              width: 7em;
            }
          </style>
          <link rel="stylesheet" href="assets/css/c3.css">
          <link rel="stylesheet" href="assets/css/bootstrap.min.css">
          <link rel="stylesheet" href="assets/css/printer-stylings.css">
        </head>
          <body>
          <div class="row printHeaderCSS">
            <div class= "col-print-2" style="border-bottom: 1px solid #000;">
            </div>
            <div class = "col-print-9 center-text">
              <h2> ${this.metaData.reportName} </h2>
              <div> Station Name : ${this.filtersData.station ? this.filtersData.station : ''}</div>
              <div>From Date: ${ date.fromDate ? date.fromDate : ''}, To Date: ${date.toDate ? date.toDate : ''} </div>
            </div>
            <div class= "col-print-1">
              <img src="assets/images/GreenLens_black.png" style = "width: 7em;">
            </div>
          </div>
          <div class="row">
            <div class="col-print-12">
               ${divContent}
            </div>
          </div>
          </body>
      </html>
    `;
    const win = window.open(`${this.metaData.reportName || 'print_chart'}.html`, '_blank');
    win.focus();
    win.document.write(template);
    this.printWindow(win);
  }
  printWindow(win) {
    setTimeout(() => {
      win.stop();
      win.print();
      win.close();
    }, 1500);
  }
  tableContentInString(delimiter) {
    let CSV = '';
    this.tableData.headerContent.forEach((element, index) => {
      let header = (element[this.metaData.headerLabel || 'name']);
      header = (typeof header !== 'string') ? JSON.stringify(header) : header;
      CSV += (header || 'NA').replace(/,|\n/g, ' ') +
        (index === this.tableData.headerContent.length - 1 ? '' : delimiter);
    });
    CSV += '\n';
    this.tableData.bodyContent.forEach(row => {
      this.tableData.headerContent.forEach((col, index) => {
        const value = (row[col[this.metaData.headerKey] || col.key] || '').toString();
        CSV += (value ? value : 'NA').replace(/,|\n/g, ' ') + (index === this.tableData.headerContent.length - 1 ? '' : delimiter);
      });
      CSV += '\n';
    });
    return CSV;
  }
  csv() {
    const payLoad = this.getPayLoad();
    try {
      this.http.printerServices.CsvDump(payLoad).subscribe(
        response => {
          try {
            if (response && response.name) {
              window.open(Config.SERVICE_IDENTIFIER.download + '?name=' + response.name);
            } else {
              this.csvBackupDownload();
            }
          } catch (error) {
            this.shared.dataNotFoundMsg(error);
            Config.logger(error);
          }
        }
      );
    } catch (error) {
      Config.logger(error);
      this.shared.internalCodeError(error);
    }
  }
  csvBackupDownload() {
    const uri = 'data:text/csv;charset=utf-8,' + escape(this.tableContentInString(','));
    const link: any = document.createElement('a');
    link.href = uri;
    link.style = 'visibility:hidden';
    link.download = this.metaData.headerContent.ReportName + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  getPayLoad() {
    return {
      dataExcel: this.tableContentInString('*'),
      headerContent: this.metaData.headerContent || { ReportName: this.metaData.fileName, ReportType: this.metaData.fileName },
      fileName: this.metaData.fileName,
      reportName: this.metaData.fileName,
      user: this.sessionService.api.local.get(Config.CONSTANTS.userName),
      userId: this.sessionService.api.local.get(Config.CONSTANTS.userId),
      dataDelimiter: '*'
    };
  }
  wordGetPayLoad() {
    return {
      dataExcel: this.tableContentInString('*'),
      headerContent: this.metaData.headerContent,
      fileName: this.metaData.fileName
    };
  }
  exportTable(type) {
    const payLoad = {
      dataExcel: this.jQueryUtility.multipleHeaderTableString(this.metaData, '*', undefined, this.id),
      dataDelimiter: '*',
      reportName: this.metaData.fileName,
      user: this.sessionService.api.local.get(Config.CONSTANTS.userName),
      userId: this.sessionService.api.local.get(Config.CONSTANTS.userId),
      fileName: this.metaData.fileName ? (this.metaData.fileName.replace(/ /gi, '') + '.' + type) : ('report.' + type),
      headerContent: {
        ReportName: this.metaData.fileName,
        ReportType: this.metaData.fileName
      }
    };
    try {
      const service = Config.CONSTANTS.reports[type];
      this.http.printerServices[service](payLoad).subscribe(
        response => {
          try {
            if (response && response.name) {
              window.open(Config.SERVICE_IDENTIFIER.download + '?name=' + response.name);
            } else {
              this.csv();
            }
          } catch (error) {
            this.shared.dataNotFoundMsg(error);
            Config.logger(error);
          }
        }
      );
    } catch (error) {
      Config.logger(error);
      this.shared.internalCodeError(error);
    }
  }
  excel() {
    const payLoad = this.getPayLoad();
    try {
      this.http.printerServices.ExcelDump(payLoad).subscribe(
        response => {
          try {
            if (response && response.name) {
              // window.open(Config.SERVICE_IDENTIFIER + `?name=${response.name}`);
              window.open(Config.SERVICE_IDENTIFIER.download + '?name=' + response.name);
            } else {
              this.csv();
            }
          } catch (error) {
            this.shared.dataNotFoundMsg(error);
            Config.logger(error);
          }
        }
      );
    } catch (error) {
      Config.logger(error);
      this.shared.internalCodeError(error);
    }
  }
  pdf() {
    const payLoad = this.getPayLoad();
    try {
      this.http.printerServices.PdfDump(payLoad).subscribe(
        response => {
          try {
            if (response && response.name) {
              // window.open(Config.SERVICE_IDENTIFIER.pdfUrl + `?name=${response.name}`);
              window.open(Config.SERVICE_IDENTIFIER.download + '?name=' + response.name);
            } else {
              this.alert.open('error', 'Failed to download, please try again', 'Failed');
            }
          } catch (error) {
            this.shared.dataNotFoundMsg(error);
            Config.logger(error);
          }
        }
      );
    } catch (error) {
      Config.logger(error);
      this.shared.internalCodeError(error);
    }
  }
  html() {
    const payLoad: any = this.wordGetPayLoad();
    payLoad.dataDelimiter = '*';
    payLoad.reportName = payLoad.headerContent.ReportType;
    try {
      this.http.printerServices.HTMLDump(payLoad).subscribe(
        response => {
          try {
            if (response && response.name) {
              // window.open(Config.SERVICE_IDENTIFIER + `?name=${response.name}`);
              window.open(Config.SERVICE_IDENTIFIER.download + '?name=' + response.name);
            } else {
              this.csv();
            }
          } catch (error) {
            this.shared.dataNotFoundMsg(error);
            Config.logger(error);
          }
        }
      );
    } catch (error) {
      Config.logger(error);
      this.shared.internalCodeError(error);
    }
  }
  word() {
    const payLoad: any = this.wordGetPayLoad();
    payLoad.dataDelimiter = '*';
    payLoad.reportName = payLoad.headerContent;
    try {
      this.http.printerServices.wordDump(payLoad).subscribe(
        response => {
          try {
            if (response && response.name) {
              // window.open(Config.SERVICE_IDENTIFIER + `?name=${response.name}`);
              window.open(Config.SERVICE_IDENTIFIER.download + '?name=' + response.name);
            } else {
              this.csv();
            }
          } catch (error) {
            this.shared.dataNotFoundMsg(error);
            Config.logger(error);
          }
        }
      );
    } catch (error) {
      Config.logger(error);
      this.shared.internalCodeError(error);
    }
  }
  /**
   * Get applied filters data
   */
  getAppliedFiltersData() {
    try {
      this.layoutService.getFiltersData().subscribe(response => {
        this.onFiltersChange(response);
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  onFiltersChange(serviceData) {
    try {
      const fields = serviceData.fields.filter(ele => !ele.hidden);
      const savedDataKeys = Object.keys(serviceData.data);
      const labels = [];
      let index;
      savedDataKeys.forEach(key => {
        index = fields.findIndex(ele => ele.key === key);
        if (index > -1) {
          const labelMappings = ['select', 'multiselect'];
          const obj = { value: serviceData.data[key], field: fields[index] };
          if (labelMappings.indexOf(fields[index].type) > -1) {
            // apply logic for multiselect here
            if (fields[index].type === 'multiselect') {
              obj.value = [];
              serviceData.data[key].forEach((val, i) => {
                obj.value.push(this.bindValueToObj(fields, index, serviceData, key, i));
              });
            } else {
              obj.value = this.bindValueToObj(fields, index, serviceData, key);
            }
          }
          labels.push(obj);
        }
      });
      this.fields = labels;
      this.fields.forEach(element => {
        this.filtersData[element.field.key] = element.value;
      });
      // Config.logger('Filters applied: ', this.fields);
      // Config.logger('Copied Filters applied: ', this.filtersData);
    } catch (error) {
      Config.logger(error);
    }
  }
  bindValueToObj(fields, index, serviceData, key, i?) {
    let value = '';
    try {
      if (typeof (fields[index].options[0]) === 'object') {
        const labelIndex = fields[index].options.findIndex(opt =>
          opt[fields[index].bindValue || 'key'] === (i !== undefined ? serviceData.data[key][i] : serviceData.data[key]));
        if (labelIndex > -1 && fields[index].options[labelIndex]) {
          value = fields[index].options[labelIndex][fields[index].bindLabel || ''];
        }
      }
    } catch (error) {
      Config.logger(error);
    }
    return value;
  }
  cloneCanvas(oldCanvas) {
    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;
    context.drawImage(oldCanvas, 0, 0);
    return newCanvas;
  }
}
