import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { DataTableConfig, TableOptions } from '../table-interface';
import { AlertService } from '../../alert.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Config } from 'src/app/config/config';
declare var $: any;

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() data;
  @Output() action: EventEmitter<any>;
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  private readonly scrollRef: CdkVirtualScrollViewport;
  public isTableLoaded = false;
  public options: TableOptions;
  public tableFilter: any = {};
  public sortData: any = {};
  public displayColumns = [];
  public dropDownSettings = {};
  public multiSearchKeys = [];
  public searchText = '';
  public pageInfo = '';
  public printMetaData: any = undefined;
  public ddItems = {};
  public pageOptions = [];
  public currentIndexNumber = 1;
  public currentPageNumber = 1;
  public itemsPerPage;
  public rangeMultiple = 10;
  public pageNumbers;
  constructor(public alert: AlertService) {
    this.action = new EventEmitter<any>();
    this.printMetaData = {
      reportName: 'Sample Report Name',
      subReportName: 'Sample Sub-Report Name',
      headerLabel: 'name',
      headerKey: 'key'
    };
  }
  ngOnInit() {
    // Config.logger(this.data);
    this.onLoadFunctions();
  }
  ngOnChanges() {
    this.onLoadFunctions();
  }
  onLoadFunctions() {
    // Config.logger(this.data)
    this.fillNullValues();
    this.setOptions();
    this.setTableFilter();
    this.setDDItems();
    this.setDisplayColumns();
    this.setDropDownSettings();
    this.setPageDDOptions();
    this.scrollToActiveRecord(this.options.scrollToRecord, 1);
  }
  setPageDDOptions() {
    this.pageOptions = [];
    for (let index = 1; index < 7; index++) {
      this.pageOptions.push((index * this.rangeMultiple));
    }
  }
  setDDItems() {
    this.ddItems = this.data.tableData.headerContent.filter(ele => !ele.refKey);
  }
  fillNullValues() {
    try {
      this.data.tableData.headerContent.forEach(th => {
        this.data.tableData.bodyContent.forEach(body => {
          if (!body[th.key] && body[th.key] !== 0) {
            body[th.key] = '';
          }
        });
      });
    } catch (error) {
      Config.logger(error);
    }
    // Config.logger(this.data.tableData);
  }
  setDropDownSettings() {
    const temp = {
      labelKey: this.data.dtOptions.headerLabel || 'label',
      primaryKey: 'key',
      badgeShowLimit: 5,
      enableCheckAll: false,
      enableSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All'
    };
    this.dropDownSettings = Object.assign(temp, this.data.dtOptions.multiselectDDOptions);
  }
  callAction(obj, key, e) {
    $('tr').removeClass('selected-row');
    $(e.currentTarget).closest('tr').addClass('selected-row');
    const rowData = JSON.parse(JSON.stringify(obj));
    let index;
    if (this.options.uniqueKey || this.options.viewRow) {
      this.data.tableData.bodyContent.forEach((element, i) => {
        if (element[this.options.uniqueKey] === rowData[this.options.uniqueKey]) {
          index = i;
          return true;
        }
      });
    }
    const event = { obj: rowData, key, index };
    this.action.emit(event);
  }
  addNewRecord() {
    this.action.emit({ key: 'addNewRow' });
  }
  setOptions() {
    const options: DataTableConfig = {
      dtOptions: {
        maxRows: 20,
        headerKey: 'key',
        serialNum: false,
        actionsAlign: 'right',
        displayOptions: false,
        searchButton: true,
        paginationFooter: false,
        headerLabel: 'label',
        searchbox: true,
        showSelectAll: true,
        hidePageInfo: false,
        hideHeaderFields: false,
        customTableClass: 'fixed-col-width',
        cellHeight: this.data.actions ? 48 : 41,
        print: {
          show: false,
          types: ['pdf', 'excel', 'csv'],
          headerContent: {},
          printMetaData: { fileName: 'Glens' },
          headerLabel: 'label',
          buttonLabel: false
        },
        columnSearch: false
      },
    };
    const parentOptions = JSON.parse(JSON.stringify(this.data.dtOptions));
    if (this.data.tableData.bodyContent.length <= parentOptions.maxRows) {
      parentOptions.maxRows = this.data.tableData.bodyContent.length;
    }
    this.options = Object.assign(options.dtOptions, parentOptions);
    this.itemsPerPage = this.rangeMultiple * (Math.ceil(this.options.maxRows / this.rangeMultiple));
    // Config.logger(this.itemsPerPage);
  }
  setDisplayColumns() {
    this.displayColumns = [];
    this.data.tableData.headerContent.forEach(element => {
      if (!element.hidden && !element.refKey) {
        this.displayColumns.push(element);
      }
    });
  }
  setTableFilter() {
    this.tableFilter = {};
    const obj = {};
    const keys = [];
    this.data.tableData.headerContent.forEach(element => {
      obj[element.key] = '';
      keys.push(element.key);
    });
    this.tableFilter = obj;
    this.multiSearchKeys = keys;
  }
  sortField(header, index) {
    try {
      if (header.key === this.sortData.key) {
        this.sortData.asc = !this.sortData.asc;
      }
      this.sortData = { key: header.key, index, asc: this.sortData.asc };
      if (header.dataType === 'number') {
        if (this.sortData.asc) {
          this.data.tableData.bodyContent = this.data.tableData.bodyContent.sort((a, b) => a[header.key] - b[header.key]);
        } else {
          this.data.tableData.bodyContent = this.data.tableData.bodyContent.sort((a, b) => b[header.key] - a[header.key]);
        }
      } else if (header.dataType === 'date') {
        if (this.sortData.asc) {
          this.data.tableData.bodyContent = this.data.tableData.bodyContent.sort((a, b) =>
            new Date(a[header.key]).getTime() - new Date(b[header.key]).getTime());
        } else {
          this.data.tableData.bodyContent = this.data.tableData.bodyContent.sort((a, b) =>
            new Date(b[header.key]).getTime() - new Date(a[header.key]).getTime());
        }
      } else {
        if (this.sortData.asc) {
          this.data.tableData.bodyContent = this.data.tableData.bodyContent.sort((a, b) => a[header.key] < b[header.key] ? -1 : 1);
        } else {
          this.data.tableData.bodyContent = this.data.tableData.bodyContent.sort((a, b) => a[header.key] > b[header.key] ? -1 : 1);
        }
      }
    } catch (error) {
      Config.logger(error);
    }
  }
  toggleHeader(data, flag) {
    this.data.tableData.headerContent.forEach(element => {
      if (element.key === data.key) {
        element.hidden = flag;
      }
    });
  }

  toggleAllHeaders(event, flag) {
    this.data.tableData.headerContent.forEach(element => {
      element.hidden = flag;
    });
    if (flag) {
      this.displayColumns.push(this.ddItems[0]);
      this.toggleHeader(this.ddItems[0], false);
    }
  }
  tableActions(type) {
    switch (type) {
      case 'add': {
        this.addNewRecord();
        break;
      }
      case 'search': {
        this.options.columnSearch = !this.options.columnSearch;
        break;
      }
      default: {
        break;
      }
    }
  }
  scrollHandler(event) {
    this.currentIndexNumber = event;
    // this.pageInfo = `Showing ${event + 1} to ${(event + 1 * this.options.maxRows)}
    this.pageInfo = `Total Records: ${this.data.tableData.bodyContent.length}`;
    this.setPagination();
  }
  getRange(data) {
    Config.logger(data);
  }
  changeMaxRows(data) {
    if (this.data.tableData.bodyContent.length >= data) {
      this.options.maxRows = data;
    } else {
      this.options.maxRows = this.data.tableData.bodyContent.length;
    }
    this.scrollHandler(this.currentIndexNumber);
  }
  setPagination() {
    const maxPageNum = Math.ceil(this.data.tableData.bodyContent.length / this.itemsPerPage);
    const pageNumbers = [];
    for (let index = 0; index < maxPageNum; index++) {
      pageNumbers.push(index + 1);
    }
    this.pageNumbers = pageNumbers;
  }
  scrollToActiveItem(index) {
    this.scrollRef.scrollToIndex((index - 1) * this.itemsPerPage);
  }
  scrollToActiveRecord(index, mul?) {
    if (!this.scrollRef) {
      setTimeout(() => { this.scrollToActiveRecord(index, mul); }, 10);
    } else {
      this.scrollRef.scrollToIndex((index - 1) * (mul || this.itemsPerPage));
    }
  }
  movePage(action) {
    this.currentPageNumber = action === 'prev' ? this.currentPageNumber - 1 : this.currentPageNumber + 1;
    this.scrollToActiveItem(this.currentPageNumber);
  }
  cellClick(row, header) {
    const event = { obj: row, key: header.action, header };
    this.action.emit(event);
  }
  toggleSelection(tr?, index?) {
    try {
      if (!tr) {
        if (!this.options.rowselect) { return; }
        this.options.rowselect.selectAll = !this.options.rowselect.selectAll;
        this.data.tableData.bodyContent.map((row) => {
          row[this.options.rowselect.key] = this.options.rowselect.selectAll;
        });
      } else {
        this.data.tableData.bodyContent[index].selected =
          ((this.data.tableData.bodyContent[index].selected === undefined) ||
            this.data.tableData.bodyContent[index].selected === false) ? true : false;
      }
      this.emitEvent();
    } catch (error) {
      Config.logger(error);
    }
  }
  emitEvent() {
    const event = { obj: this.data, key: 'toggleCheckbox' };
    this.action.emit(event);
  }
  toggleRow(row) {
    row[this.options.rowselect.key] = !row[this.options.rowselect.key];
    if (!row[this.options.rowselect.key]) {
      this.options.rowselect.selectAll = false;
    }
  }
}
