import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JqueryUtilitiesService {

  constructor() { }

  multipleHeaderTableString(meta, delimiter, totalColumns, id?) {
    let payload = '';
    const tableId = '#' + (meta.printMetaData.id || id);
    if (typeof (delimiter) === 'undefined') {
      delimiter = '||';
    }
    const getTable: any = $(tableId).dataTable();
    const rows = getTable.fnGetNodes();
    const visibleColumns = [];
    let columnCount = 0;
    let csvData = '';
    const headers = [];

    // tslint:disable-next-line:space-before-function-paren
    $(tableId).find('th').each(function () {
      if ($(this).is(':visible') && $(this).html().trim().length > 0) {
        if (totalColumns && totalColumns.length > 0) {
          if ($.inArray(columnCount.toString(), totalColumns) >= 0) {
            headers.push($(this).html().trim());
            visibleColumns.push(columnCount);
          }
        } else {
          if ($(this).attr('ignoreHeader') && $(this).attr('multipleheadercolumn')) {
            const multiHead = $(this).attr('multipleheadercolumn');
            const theads: any = $(tableId + ' thead tr th');
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < theads.length; i++) {
              if (theads[i].attributes.multipleColumn) {
                if (multiHead === theads[i].attributes.multipleColumn.value) {
                  let head = $(this).html() + '(' + theads[i].innerText + ')';
                  head = head.replace(/\n/gi, ' ').replace(',', ' ');
                  headers.push(head);
                }
              }
            }
          } else if ($(this).attr('ignoreHeader') && !$(this).attr('multipleheadercolumn')) {

          } else if (!$(this).attr('ignoreHeader') && $(this).attr('lastLevel')) {
            let head1 = $(this).attr('multipleColumn');
            head1 = head1.replace(/\n/gi, ' ').replace(',', ' ');
            headers.push(head1);
          } else if ($(this).attr('aqi')) {
            if ($(this).attr('parentLabel') && $(this).attr('currentLabel')) {
              const head = $(this).text();
              const parentLabel = $(this).attr('parentLabel');
              let header = parentLabel + ' ' + head;
              header = header.replace(/\n/gi, ' ').replace(',', ' ');
              headers.push(header);
            } else if ($(this).attr('date')) {
              let header = $(this).text();
              header = header.replace(/\n/gi, ' ').replace(',', ' ');
              headers.push(header);
            }
          } else {
            let head = $(this).html();
            head = head.replace(/\n/gi, ' ').replace(',', ' ');
            headers.push(head);
          }
          visibleColumns.push(columnCount);
        }
      }
      columnCount++;
    });
    csvData = csvData + headers.join(delimiter) + '\n';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < rows.length; i++) {
      const rowData = [];
      columnCount = 0;
      // tslint:disable-next-line:space-before-function-paren
      $(rows[i]).find('td').each(function () {
        let instanceColumn = '';
        if (!$(this).attr('skipTD')) {
          instanceColumn = $(this).text().trim();
          rowData.push(instanceColumn);
          columnCount++;
        }
      });
      csvData = csvData + rowData.join(delimiter) + '\n';
    }
    payload = csvData;

    return payload;
  }
}
