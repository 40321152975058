import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UserLoginComponent } from './login/user-login/user-login.component';
import { ManualComplaintsComponent } from './login/manual-complaints/manual-complaints.component';
import { TrackComplaintsComponent } from './login/track-complaints/track-complaints.component';
import { CaaqmsReportDownloaderComponent } from './login/caaqms-report-downloader/caaqms-report-downloader.component';
import { AqiPortalDataComponent } from './login/aqi-portal-data/aqi-portal-data.component';
import { ComplaintTrackerComponent } from './login/complaint-tracker/complaint-tracker.component';
const routes: Routes = [
  { path: '', redirectTo: 'user-login', pathMatch: 'full' },
  { path: 'login', component: UserLoginComponent },
  { path: 'complaints', component: ManualComplaintsComponent },
  { path: 'track-complaints', component: TrackComplaintsComponent },
  { path: 'complaint-tracker', component: ComplaintTrackerComponent },
  { path: 'caaqms-report', component: CaaqmsReportDownloaderComponent },
  { path: 'aqi-portal-data', component: AqiPortalDataComponent },
  { path: 'user-login', component: UserLoginComponent },
  { path: 'landing', loadChildren: './layout/layout.module#LayoutModule' }, // canActivate: [AuthGuardGuard]
  { path: 'iframes', loadChildren: './iframes/iframes.module#IframesModule' } // canActivate: [AuthGuardGuard]
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
