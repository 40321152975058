import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckBoxComponent } from './check-box/check-box.component';
import { SimpleDfmComponent } from './simple-dfm/simple-dfm.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { PrinterComponent } from './printer/printer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiKeysPipePipe } from './multi-keys-pipe.pipe';
import { Bs64UploadComponent } from './bs64-upload/bs64-upload.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { MultipleHeadersTableComponent } from './multiple-headers-table/multiple-headers-table.component';
import { AppliedFilterDisplayComponent } from './applied-filter-display/applied-filter-display.component';
import { ListDisplayComponent } from './list-display/list-display.component';
import { CriteriaInfoDisplayComponent } from './criteria-info-display/criteria-info-display.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { SearchTxtHighlightPipe } from './search-txt-highlight.pipe';
import { InfoLabelsDisplayComponent } from './info-labels-display/info-labels-display.component';
import { ColorsTableComponent } from './colors-table/colors-table.component';
import { DataTablesModule } from 'angular-datatables';
import { DisplayFiltersButtonComponent } from './display-filters-button/display-filters-button.component';
import { TimeAgoPipe } from 'time-ago-pipe';
import { MediaViewerComponent } from './media-viewer/media-viewer.component';
import { VerticalSlidingParamsComponent } from './vertical-sliding-params/vertical-sliding-params.component';

@NgModule({
  declarations: [
    CheckBoxComponent,
    SimpleDfmComponent,
    PrinterComponent,
    MultiKeysPipePipe,
    Bs64UploadComponent,
    SiteHeaderComponent,
    MultipleHeadersTableComponent,
    AppliedFilterDisplayComponent,
    ListDisplayComponent,
    CriteriaInfoDisplayComponent,
    DateTimePickerComponent,
    MediaViewerComponent,
    SearchTxtHighlightPipe,
    InfoLabelsDisplayComponent,
    ColorsTableComponent,
    DisplayFiltersButtonComponent,
    MediaViewerComponent,
    VerticalSlidingParamsComponent,
    TimeAgoPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    AngularMultiSelectModule,
    AngularDateTimePickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FilterPipeModule,
    NgbModule,
    DataTablesModule
  ],
  exports: [
    CheckBoxComponent,
    SimpleDfmComponent,
    PrinterComponent,
    MultiKeysPipePipe,
    Bs64UploadComponent,
    SiteHeaderComponent,
    MultipleHeadersTableComponent,
    AppliedFilterDisplayComponent,
    MediaViewerComponent,
    ListDisplayComponent,
    CriteriaInfoDisplayComponent,
    SearchTxtHighlightPipe,
    InfoLabelsDisplayComponent,
    ColorsTableComponent,
    DateTimePickerComponent,
    DisplayFiltersButtonComponent,
    MediaViewerComponent,
    VerticalSlidingParamsComponent,
    TimeAgoPipe
  ]
})
export class SharedModule { }
