import { Component, OnInit, Input, Output, OnChanges } from '@angular/core';
import { SharedService } from '../shared.service';
import { EventEmitter } from '@angular/core';
import { AlertService } from '../alert.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-simple-dfm',
  templateUrl: './simple-dfm.component.html',
  styleUrls: ['./simple-dfm.component.scss']
})
export class SimpleDfmComponent implements OnInit {
  objectKeys = Object.keys;
  @Input() metaData;
  @Input() parentData;
  @Input() savedData;
  @Output() selectFieldChange: EventEmitter<any>;


  constructor(
    private sharedService: SharedService,
    public alert: AlertService
  ) {
    this.selectFieldChange = new EventEmitter<any>();
  }
  ngOnInit() {
    setTimeout(() => {
      this.triggerSavedSelectChange();
    }, 300);
  }
  validateField(data, type?) {
    return this.sharedService.validateField(data, type);
  }
  triggerSavedSelectChange() {
    try {
      // dependency
      const typeKeys = [
        'dependency',
        'multipleFiledDependencies',
        'multipleDependency',
        'serviceDependency'
      ];
      typeKeys.forEach(typeKey => {
        const indexes = [];
        this.metaData.forEach((ele, i) => {
          if (ele[typeKey]) {
            indexes.push(i);
          }
        });
        indexes.forEach(index => {
          if (!this.metaData[index].hidden) {
            let selectedVal;
            if (this.savedData[this.metaData[index].key]) {
              selectedVal = this.savedData[this.metaData[index].key];
            } else {
              if (this.metaData[index] && this.metaData[index].options && this.metaData[index].options[0]) {
                if (typeof this.metaData[index].options[0] === 'object') {
                  selectedVal = this.metaData[index].options[0][this.metaData[index].bindValue || 'key'];
                } else {
                  selectedVal = this.metaData[index].options[0];
                }
              }
              selectedVal = this.metaData[index].type === 'select' ? selectedVal : [selectedVal];
            }
            if (selectedVal) {
              this.savedData[this.metaData[index].key] = selectedVal;
              if (this.metaData[index].type === 'select') {
                this.selectChange(selectedVal, this.metaData[index]);
              } else {
                this.multiSelectChange(selectedVal, this.metaData[index]);
              }
            }
          }
        });
      });
      // hidden dependency
      this.metaData.forEach((element, i) => {
        if (element.hiddenDependency === true) {
          if (this.savedData[this.metaData[i].key]) {
            this.selectChange(
              this.savedData[this.metaData[i].key],
              this.metaData[i]
            );
          }
        }
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  multiSelectChange(event, field) {
    try {
      if (field.dependency) {
        const index = this.metaData.findIndex(
          ele => ele.key === field.dependencyKey
        );
        this.setMultiSelectDependentOptions(index, event, field, field.dependencyKey);
      } else if (field.multipleFiledDependencies) {
        const indexes = [];
        field.dependencyKeys.forEach(element => {
          indexes.push(this.metaData.findIndex(ele => ele.key === element));
        });
        indexes.forEach(index => {
          let options = [];
          event.forEach(eachOpt => {
            options = [...options, ...field.dependentOptions[eachOpt][this.metaData[index].key]
            ];
          });
          this.metaData[index].options = options;
          // bind the previous selected value or 1st value from dropDown
          if (this.metaData[index].type === 'multiselect') {
            this.setMultiSelectDependencyValues(index, field.dependencyKey);
          } else {
            this.setDefaultValue(index, field.dependencyKey);
          }
        });
      } else if (field.multipleDependency) {
        field.dependencyKeys.forEach((element, i) => {
          const index = this.metaData.findIndex(ele => ele.key === element);
          this.setMultiSelectDependentOptions(index, event, field, field.dependencyKeys[i]);
        });
      }
      if (field.bindNameTo && event) {
        field.bindNameTo = [];
        let index;
        event.forEach(ele => {
          index = field.options.findIndex(
            opt => opt[field.bindValue] === event
          );
          if (index > -1) {
            this.savedData[field.bindNameTo].push(field.options[index][field.bindLabel]);
          }
        });
      }
    } catch (error) {
      Config.logger(error);
    }
  }
  setMultiSelectDependentOptions(index, event, field, dependencyKey) {
    this.metaData[index].options = [];
    event.forEach(element => {
      this.metaData[index].options = [...field.dependentOptions[element], ...this.metaData[index].options];
    });
    // bind the previous selected value or 1st value from dropDown
    if (this.metaData[index].type === 'multiselect') {
      this.setMultiSelectDependencyValues(index, dependencyKey);
    } else {
      this.setDefaultValue(index, field.dependencyKey);
    }
  }
  setMultiSelectDependencyValues(index, key) {
    if (this.metaData[index].options && this.metaData[index].options[0]) {
      if (this.savedData[key]) {
        const selectedOptions = this.savedData[key];
        const savedOptions = [];
        selectedOptions.forEach(ele => {
          if (this.metaData[index].options.findIndex(obj => obj.key === ele) > -1) {
            savedOptions.push(ele);
          }
        });
        this.savedData[key] = savedOptions;
      } else {
        this.savedData[key] = [this.metaData[index].options[0].key];
      }
    } else {
      this.savedData[key] = undefined;
    }
    this.multiSelectChange(this.savedData[key], this.metaData[index]);
  }
  findInOptions(item, field) {
    try {
      // tslint:disable-next-line:triple-equals
      const index = field.options.findIndex(ele => {
        if (typeof ele === 'object') {
          return ele[field.bindValue || 'value'] === item;
        } else {
          return ele === item;
        }
      });
      if (index > -1) {
        return item;
      } else {
        return null;
      }
    } catch (error) {
      Config.logger(error);
      return null;
    }
  }
  selectChange(event, field) {
    try {
      if (field.dependency) {
        const index = this.metaData.findIndex(
          ele => ele.key === field.dependencyKey
        );
        this.metaData[index].options = field.dependentOptions[event];
        this.setDefaultValue(index, field.dependencyKey);
      } else if (field.multipleDependency) {
        field.dependencyKeys.forEach(element => {
          const index = this.metaData.findIndex(ele => ele.key === element);
          if (index > -1) {
            this.metaData[index].options = field.dependentOptions[event];
            this.setDefaultValue(index, element);
          }
        });
      } else if (field.multipleFiledDependencies) {
        const indexes = [];
        field.dependencyKeys.forEach(element => {
          indexes.push(this.metaData.findIndex(ele => ele.key === element));
        });
        indexes.forEach(index => {
          this.metaData[index].options = field.dependentOptions[event][this.metaData[index].key];
          this.setDefaultValue(index, field.dependencyKey);
        });
      }
      if (field.hiddenDependency) {
        const hiddenDependencyFields = [];
        Object.keys(field.displayOn).forEach(ele => {
          field.displayOn[ele].forEach(element => {
            hiddenDependencyFields.push(element);
          });
        });
        let indexes = this.getDependencyIndexes(hiddenDependencyFields);
        indexes.forEach(index => {
          if (this.metaData[index]) {
            this.metaData[index].hidden = true;
            // delete this.savedData[this.metaData[index].key];
          }
        });
        if (field.displayOn[event]) {
          indexes = this.getDependencyIndexes(field.displayOn[event]);
          indexes.forEach(index => {
            if (this.metaData[index]) {
              this.metaData[index].hidden = false;
            }
          });
        }
      }
    } catch (error) {
      Config.logger(error);
    }
    try {
      if (field.serviceDependency) {
        const index = this.metaData.findIndex(
          ele => ele.key === field.dependencyKey
        );
        this.setDefaultValue(index, field.dependencyKey);
      }
      if (field.bindNameTo) {
        const index = field.options.findIndex(
          opt => opt[field.bindValue] === event
        );
        if (index > -1) {
          this.savedData[field.bindNameTo] = field.options[index][field.bindLabel];
        }
      }
    } catch (error) {
      Config.logger(error);
    }
    const obj = { value: event, field };
    this.selectFieldChange.emit(obj);
  }
  getIndex(key) {
    return this.metaData.findIndex(ele => ele.key === key);
  }
  setDefaultValue(index, dependencyKey) {
    if (this.metaData[index].options && this.metaData[index].options[0]) {
      let value;
      if (this.metaData[index].type === 'multiselect') {
        value = [];
        if (this.savedData[this.metaData[index].key] && this.savedData[this.metaData[index].key].length > 0) {
          this.savedData[this.metaData[index].key].forEach(element => {
            if (this.findInOptions(element, this.metaData[index])) {
              value.push(element);
            }
          });
        } else {
          value = this.getFirstOption(index, 'multiselect');
        }
      } else {
        if (this.findInOptions(this.savedData[dependencyKey], this.metaData[index])) {
          value = this.findInOptions(this.savedData[dependencyKey], this.metaData[index]);
        } else {
          value = this.getFirstOption(index, 'select');
        }
      }
      this.savedData[this.metaData[index].key] = value;
    } else {
      this.savedData[this.metaData[index].key] = undefined;
    }
    this.selectChange(this.savedData[this.metaData[index].key], this.metaData[index]);
  }
  getFirstOption(index, type) {
    try {
      let val;
      if (typeof this.metaData[index].options[0] === 'object') {
        val = this.metaData[index].options[0][this.metaData[index].bindValue || 'key'];
      } else {
        val = this.metaData[index].options[0];
      }
      return type === 'multiselect' ? [val] : val;
    } catch (error) {
      return undefined;
    }
  }
  getDependencyIndexes(fields) {
    const indexes = [];
    try {
      fields.forEach(element => {
        indexes.push(this.metaData.findIndex(ele => ele.key === element));
      });
      return indexes;
    } catch (error) {
      Config.logger(error);
      return [];
    }
  }
  bs64Emitter(event, field) {
    if (event === 'invalidFormat') {
      this.alert.open('info', `Only ${field.accept} files are allowed`, 'Invalid File');
    }
  }
  radioFieldUpdate(key, value, option) {
    this.savedData[key] = value;
  }
  isRadioChecked(key, value, option) {
    return this.savedData[key] === value;
  }
  dateOutput(event,fieldKey) {
    try {
      this.savedData[fieldKey] = event;
    } catch (error) {
      console.log(error);
    }
  }
}
