import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { LoaderCommonComponent } from './shared/loader/loader-common.component';
import { LoaderService } from './shared/loader/loader-service.service';
import { HttpLayerService } from './services/http-layer.service';
import { HttpClientModule } from '@angular/common/http';
import { SharedService } from './shared/shared.service';
import { AlertService } from './shared/alert.service';
import { SessionhandlerService } from './services/sessionhandler.service';
import { DataTablesModule } from 'angular-datatables';

@Injectable()


@NgModule({
    declarations: [
        AppComponent,
        LoaderCommonComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        LoginModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DataTablesModule
    ],
    exports: [LoaderCommonComponent],
    providers: [LoaderService,
        HttpLayerService,
        SharedService,
        AlertService,
        SessionhandlerService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
