import { Component, OnInit, Input } from '@angular/core';
import { CommonServiceLayerService } from 'src/app/services/common-service-layer.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Config } from '../../../config/config';
import { Router } from '@angular/router';
import { GlobalVariablesService } from 'src/app/layout/global-variables.service';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-public-aqi-map-dashboard',
  templateUrl: './public-aqi-map-dashboard.component.html',
  styleUrls: ['./public-aqi-map-dashboard.component.scss']
})
export class PublicAqiMapDashboardComponent implements OnInit {
  @Input() landingPage: any;
  public Object = Object;
  public standardsMapping: any = Config.CONSTANTS.AQI_STANDARDS;
  public aqiLayerTypes: any = Config.CONSTANTS.AQI_LAYER_TYPES;
  public layerType: any = 'AQI';
  public markers: any = [];
  public standards: any;
  public markerData: any;
  public selectedMarkerInfo: any;
  public markersList: any;
  public lat: any; // 25.143643; // To Display default latitude
  public lng: any; // 56.323126; // To Display default longitude
  public geoJsonObject;
  public mapZoom: Number = 7;
  public dataTableOptions: any = {
    pageLength: 10,
    order: [],
    columnDefs: [],
    paging: true,
    ordering: true,
    info: false
  };
  public activeTab: any = 'info';
  public tabs = [
    { key: 'info', label: 'Info' },
    { key: 'hourly', label: 'Hourly Average' }
  ];
  public staticJson: any = Config.CONSTANTS.staticRequestJson;
  public markerType: any;
  public showNoiseData = false;
  public noiseTableData: any;
  public noiseLimit: any;
  constructor(
    public http: CommonServiceLayerService,
    public sharedService: SharedService,
    public router: Router,
    public globalvariables: GlobalVariablesService,
    public layoutService: LayoutService,
  ) { }
  public stationType: any;
  public stateName: any;

  ngOnInit() {
    this.stateName = JSON.parse(localStorage.getItem('state'));
    this.loadMap();
    this.fetchAQIMetrics();
    this.loadGeoJson();
    this.layoutService.getStaticFilterFields().subscribe(response => {
      const index = response.findIndex(ele => ele.key === 'stationType');
      if (response[index]) {
        this.stationType = response[index].options;
        this.markerType = this.stationType[1].key;
      }
    });
    this.adjustMapZoom();
  }

  adjustMapZoom(){
    setTimeout(() => {
      this.mapZoom = 15;
    }, 800);
  }

  loadMap(event?) {
    let payLoad = { pollutant: 'AQI', layerType: this.layerType };
    if (this.landingPage) {
      payLoad = Object.assign(this.staticJson, payLoad);
    }
    try {
      this.http.realTimeDashboardModule.publicAqiMap(payLoad, this.landingPage).subscribe(response => {
        if (response.status) {
          this.markers = response.station_list;
          this.markersList = response.station_list;
          this.markers.forEach(element => {
            element.options = {
              color: (element.remarks === 'Satisfactory' || element.remarks === 'Moderate') ? 'black' : 'white',
              fontWeight: 'bold',
              fontSize: '0.6rem',
              text: '' + this.getValue(element)
            };
            const icon = {
              url: '',
              scaledSize: {
                width: 30,
                height: (this.layerType === 'WIND') ? 40 : 30
              }
            };
            switch (element.remarks) {
              case 'Good':
                icon.url = 'assets/images/markers/value-good.png';
                break;
              case 'Satisfactory':
                icon.url = 'assets/images/markers/value-satisfactory.png';
                break;
              case 'Moderate':
                icon.url = 'assets/images/markers/value-moderate.png';
                break;
              case 'Poor':
                icon.url = 'assets/images/markers/value-poor.png';
                break;
              case 'Very Poor':
                icon.url = 'assets/images/markers/value-verypoor.png';
                break;
              case 'Severe':
                icon.url = 'assets/images/markers/value-severe.png';
                break;
              case 'NA':
                if (this.layerType === 'WIND') {
                  icon.url = 'assets/images/markers/arrow.png';
                } else if (this.layerType === 'BARPRESS' || this.layerType === 'SWDR') {
                  icon.url = 'assets/images/markers/value-moderate.png';
                } else {
                  icon.url = 'assets/images/markers/value-NA.png';
                }
                break;
              default:
                icon.url = 'assets/images/markers/value-good.png';
                break;
            }
            element.icon = icon;
            this.loadMarker();
          });
        } else {
          this.sharedService.serviceErrorMessage(response.message);
        }
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  getValue(element) {
    if (this.layerType === 'AQI') {
      return element.aqi;
    } else if (this.layerType === 'WIND') {
      return element.aqiWS;
    } else if (this.layerType === 'BARPRESS') {
      return element.aqiBP;
    } else if (this.layerType === 'SWDR') {
      return element.aqiWS; // aqiWD;
    } else {
      return 'NA';
    }
  }
  fetchAQIMetrics() {
    let payLoad = { standardFormat: '' };
    if (this.landingPage) {
      payLoad = Object.assign(this.staticJson, payLoad);
    }
    try {
      this.http.realTimeDashboardModule.standard_metadata(payLoad, this.landingPage).subscribe(response => {
        if (response.status) {
          this.standards = response.standard_color.AQI;
        } else {
          this.sharedService.serviceErrorMessage(response.message);
        }
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  loadGeoJson() {
    try {
      this.http.anayticReports.loadStateGeoJson({}).subscribe(response => {
        // this.http.anayticReports.loadGeoJson(this.landingPage).subscribe(response => {
        if (response.status) {
          this.lat = response.center.latitude ? response.center.latitude : 20.5937;
          this.lng = response.center.longitude ? response.center.longitude : 78.9629;
          this.geoJsonObject = response.geoJson;
        } else {
          this.sharedService.serviceErrorMessage(response.message);
        }
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: '#50a3ea', // feature.getProperty('color'),
      strokeWeight: 1,
      stroke: '#50a3ea'
    });
  }
  setStringPadding(str) {
    return ('0' + str).slice(-2);
  }
  getMonth(month) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[month];
  }
  getData(date) {
    try {
      const dt = new Date(date);
      const HH = (dt.getHours() > 9) ? dt.getHours() : ('0' + dt.getHours());
      const MM = (dt.getMinutes() > 9) ? dt.getMinutes() : ('0' + dt.getMinutes());
      const SS = (dt.getSeconds() > 9) ? dt.getSeconds() : ('0' + dt.getSeconds());
      return this.setStringPadding
        (dt.getDate()) + '-' + this.getMonth(dt.getMonth()) + '-' + dt.getFullYear() + ' ' + HH + ':' + MM + ':' + SS;
    } catch (error) {
      Config.logger(error);
    }
  }
  mapMarkerClick(marker) {
    try {
      if (this.layerType === 'AQI') {
        this.markerData = undefined;
        const inputJson = this.selectedMarkerInfo = {
          site: marker.station_id,
          site_status: marker.status || marker.station_status,
          graph: 'line',
          station_type: marker.station_name,
          station_type_Name: marker.station_name,
          frequency: '-1day',
          fromDate: this.getData(new Date()),
          pollutant: 'AQI'
        };
        this.selectedMarkerInfo['fromDate'] = this.getData(new Date());
        this.selectedMarkerInfo['responsibleParam'] = marker.responsibleParam;
        this.selectedMarkerInfo['aqi'] = marker.aqi;
        this.http.realTimeDashboardModule.paramChart(inputJson, this.landingPage).subscribe(response => {
          if (response && response.status) {
            this.markerData = response;
            this.formatTableData();
            this.activeTab = 'info';
            document.getElementById('markerModal').click();
          } else {
            this.sharedService.serviceErrorMessage(response.message);
          }
        });
      }
    } catch (e) {
      Config.logger(e);
    }
  }
  formatTableData() {
    const tableData = {
      headerContent: [{ label: 'Hour', key: 'hour' }],
      bodyContent: []
    };
    this.markerData.xAxis.forEach(element => {
      tableData.bodyContent.push({ hour: element });
    });
    try {
      this.markerData.Chartdata.forEach((element, index) => {
        tableData.headerContent.push({ label: element.name, key: element.name });
        element.data.forEach((obj, objIndex) => {
          if (tableData.bodyContent[objIndex]) {
            tableData.bodyContent[objIndex][element.name] = obj.value[1];
            tableData.bodyContent[objIndex].color = obj.itemStyle.color;
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
    this.markerData.tableData = tableData;
  }
  onMouseOver(infoWindow) {
    infoWindow.open();
  }
  onMouseOut(infoWindow) {
    infoWindow.close();
  }
  navigateToLoginPage() {
    this.router.navigate(['/user-login']);
  }
  loadMarker() {
    this.markers = [];
    this.mapZoom = 7;
    this.markersList.forEach(element => {
      if (element.type === this.markerType) {
        this.markers.push(element);
      }
    });
    if(this.markers.length === 0){
      // this.markerType = 'aaqms';
      this.markers.push({
        "station_id": "0",
        "station_name": "Nill",
        "longitude": "78.334361",
        "latitude": "17.460103",
        "color": "#ffffff00",
        "type": "aaqms",
        "station_status": "Nill",
        "responsibleParam": "Nill",
        "aqi": "Nill",
        "remarks": "Nill",
        "icon": {
          "url": "assets/images/markers/transparent.png",
          "scaledSize": {
            "width": 30,
            "height": 30
          }
        }
      })
      // this.mapZoom = 2;

      this.lat = "17.460103";
      this.lng = "78.334361";


    }
    this.adjustMapZoom();
  }
  getNoiseLoadMap(param) {
    this.markers = [];
    const payLoad = { type: "noise" };
    this.showNoiseData = (param === 'noise') ? true : false;
    if (this.showNoiseData) {
      this.http.realTimeDashboardModule.getNoiseStationList(payLoad, this.landingPage).subscribe(response => {
        if (response.status) {
          this.markers = response.noiseStationList;
          this.noiseTableData = response.noiseTableData;
          this.noiseLimit = response.limitJsos;
        }
      });
    }
    else {
      this.loadMap();
    }
  }
}
