import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';
import { CommonServiceLayerService } from 'src/app/services/common-service-layer.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-complaint-tracker',
  templateUrl: './complaint-tracker.component.html',
  styleUrls: ['./complaint-tracker.component.scss']
})
export class ComplaintTrackerComponent implements OnInit {

  public loginLogo = { logo: '', title: '' };
  public complaintId:any;
  public complaintDetails:any ={};
  public imageData:any;
  public complaintInfo:any;

  constructor(private http: CommonServiceLayerService,private router: Router, private sharedService: SharedService) { }

  ngOnInit() {
    this.getStateLogo()
  }
  getStateLogo() {
    try {
      this.http.others.getStateMetaData().subscribe(
        response => {
          this.loginLogo = response[window.location.hostname] ? response[window.location.hostname] : response['default'];
          return this.loginLogo;
        });
    } catch (error) {
      Config.logger(error);
    }
  }

  validateComplaintId = (event:any) => {
    const complaintIdPattern = /^(complaint_)+[0-9]{1,}$/;
    if(!complaintIdPattern.test(event)){
      this.sharedService.serviceErrorMessage('Please enter a valid Complaint Id ( complaint_xxx )');
      return false
    }
    else{
      return true
    }
  }

  getComplaintDetails = (event:any) => {
    if(this.validateComplaintId(event)){
      const payLoad:any ={
        complaint_id : event,
        state : 'Telangana'
      }
      this.complaintInfo =''
      try {
        this.http.getComplaintDetails(payLoad).subscribe((response:any) => {
          if(response && response.status === 'success'){
            this.complaintDetails = response.data
          }
          else if(response && response.status === 'info'){
            this.complaintInfo = response.message
            this.complaintDetails = {}
          }
          else{
            this.complaintDetails ={}
            this.complaintInfo = ''
            this.sharedService.serviceErrorMessage(response.message);
          }
        });
      } catch (error) {
        Config.logger(error);
      }
    }
    else{
      this.complaintDetails ={}
      this.complaintInfo = ''
    }
  }


  goToLogin = () => {
    this.router.navigate(['/login']);
  }

  viewImage(event) {
    this.imageData=event
    document.getElementById('viewImage').click();
  }

}
