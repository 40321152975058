import { Component, OnInit, Inject } from '@angular/core';
import { CommonServiceLayerService } from './services/common-service-layer.service';
import { Config } from './config/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public http: CommonServiceLayerService
  ) { }
  public loginLogo: any;
  ngOnInit() {
    this.getStateLogo();
  }
  getStateLogo() {
    try {
      this.http.others.getStateMetaData().subscribe(
        response => {
          this.loginLogo = response[window.location.hostname] ? response[window.location.hostname] : response['default'];
          return this.loginLogo;
        });
    } catch (error) {
      Config.logger(error);
    }
  }
  getHeaderData() {
    return this.loginLogo ? this.loginLogo : '';
  }
}
