import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';


@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {
  @Input() options: DatePickerOptions;
  @Input() inputModel;
  @Output() inputModelChange = new EventEmitter<string>();
  public currentDate: any = new Date();
  public temp: any;

  constructor(
    public layoutService: LayoutService,
  ) { }

  ngOnInit() {
    this.temp = this.layoutService.getDateFlag();
    this.checkValidDate();
    
  }
  checkValidDate() {
    if(this.temp === true){
      if(!this.inputModel){
        this.temporary();
      }
    }else{
      this.temporary();
    }
    
  }

  temporary(){
    const fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 1);
        fromDate.setHours(0, 0, 0, 0);
        const toDate = new Date();
        // toDate.setHours(0, 0, 0, 0);
        if (this.options.selectMode === 'range') {
          this.inputModel = [fromDate, toDate];
        } else {
          this.inputModel = new Date();
        }
        setTimeout(() => {
          this.inputModelChange.emit(this.inputModel);
        }, 300);
  }
}

export class DatePickerOptions {
  selectMode: 'range' | 'single';
  required: boolean;
  fromDateLabel?: string;
  toDateLabel?: string;
  name?: string;
  pickerType: 'both' | 'calendar' | 'timer';
}
