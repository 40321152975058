import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { SessionhandlerService } from '../services/sessionhandler.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor(public storage: SessionhandlerService) { }
  private toggleModal = new BehaviorSubject<any>(false);
  private siteData = new BehaviorSubject<any>({});
  public staticFilter = {};
  public staticFilterFields = new BehaviorSubject<any>([]);
  public allFilters = new BehaviorSubject<any>({ fields: [], data: {} });
  public dateFlag : boolean;
  public staticCalenderDFM = [
    {
      disabled: true,
      selectMode: 'range',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'both',
      columns: '8',
      identifier: 'dateRange'
    },
    {
      disabled: true,
      selectMode: 'range',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'calendar',
      columns: '4',
      identifier: 'calendarRange'
    },
    {
      disabled: true,
      selectMode: 'range',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'timer',
      columns: '4',
      identifier: 'timeRange'
    },
    {
      disabled: true,
      selectMode: 'single',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'both',
      columns: '4',
      identifier: 'singleDate'
    },
    {
      disabled: true,
      selectMode: 'single',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'calendar',
      columns: '4',
      identifier: 'singleCalendar'
    },
    {
      disabled: true,
      selectMode: 'single',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'timer',
      columns: '4',
      identifier: 'singleTime'
    }
  ];
  public duplicates = {
    stations: {
      key: 'stations',
      placeholder: 'Stations',
      bindValue: 'key',
      name: 'Stations',
      required: true,
      bindLabel: 'label',
      type: 'multiselect',
      options: [],
      dependentOptions: {},
      multipleDependency: true,
      dependencyKeys: ['parameter', 'parameters']
    }
  };
  setToggleModal(flag) {
    if (!flag) {
      document.getElementById('filterModalCloseBtn').click();
    }
    this.toggleModal.next(flag);
  }
  getToggleModal(): Observable<any> {
    return this.toggleModal.asObservable();
  }
  setFiltersData(data) {
    this.allFilters.next(data);
  }
  getFiltersData() {
    return this.allFilters.asObservable();
  }
  setSiteData(data) {
    this.storage.api.local.save('siteId', data.key);
    this.storage.api.local.save('siteName', data.label);
    this.siteData.next(data);
  }
  getSiteData(): Observable<any> {
    return this.siteData.asObservable();
  }
  getSitesListDD(list) {
    const keys = Object.keys(list.siteName);
    const optionsList = [];
    keys.forEach(ele => {
      optionsList.push({ key: ele, label: list.siteName[ele] });
    });
    return optionsList;
  }

  // global filters code
  getAppliedStaticFilters() {
    return this.staticFilter;
  }
  setAppliedStaticFilters(data) {
    this.staticFilter = data;
  }
  // DFM JSON
  setStaticFilterFields(data) {
    this.staticFilterFields.next([...this.staticCalenderDFM, ...data]);
  }
  getStaticFilterFields(): Observable<any> {
    return this.staticFilterFields.asObservable();
  }
  setDateFlag(data){
    this.dateFlag = data;
  }
  getDateFlag(){
    return this.dateFlag;
  }
}
