import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { ModalFilterData } from './filter-modal-interface';
import { LayoutService } from 'src/app/layout/layout.service';
import { DataTableConfig } from '../../table/table-interface';
import { CommonServiceLayerService } from 'src/app/services/common-service-layer.service';
import { SharedService } from '../../shared.service';
import { SessionhandlerService } from '../../../services/sessionhandler.service';
import { AlertService } from 'src/app/shared/alert.service';
import { GlobalVariablesService } from 'src/app/layout/global-variables.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit, OnChanges {
  selectedSite: any;

  constructor(
    public modalService: LayoutService,
    public http: CommonServiceLayerService,
    public sharedService: SharedService,
    public alert: AlertService,
    public layoutService: LayoutService,
    public storage: SessionhandlerService,
    public globalVariables: GlobalVariablesService
  ) {
    this.modalAction = new EventEmitter<any>();
    this.selectFieldChanges = new EventEmitter<any>();
  }
  @Input() data: ModalFilterData;
  @Output() modalAction: EventEmitter<any>;
  @Output() selectFieldChanges: EventEmitter<any>;
  public parentData: any = {
    error_cls: 'error_cls',
    formSubmitted: false,
  };
  public tableConfigData: DataTableConfig;
  public staticForm = [];
  public selectedStaticFields = {};
  ngOnInit() {
    this.onLoadFunctions();
  }
  setStaticForm(res) {
    try {
      const dateTimeFilters = this.globalVariables.FILTER_FORM_MAPPING.dateTimeFilters;
      this.staticForm = res;
      this.staticForm.forEach(element => {
        element.hidden = true;
      });
      this.data.staticFields.forEach(field => {
        let index;
        if (dateTimeFilters.indexOf(field) > -1) {
          index = this.staticForm.findIndex(ele => ele.identifier === field);
        } else {
          index = this.staticForm.findIndex(ele => ele.key === field);
        }
        if (index > -1) {
          this.staticForm[index].hidden = false;
        }
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  ngOnChanges() {
    this.onLoadFunctions();
  }
  onLoadFunctions() {
    this.setTableConfig();
    this.setDefaultOptions();
    this.layoutService.getStaticFilterFields().subscribe(res => {
      this.setStaticForm(res);
    });
    // previous selected filters
    this.selectedStaticFields = this.data.inputData = this.layoutService.getAppliedStaticFilters();
    this.parentData.noDynamicFieldWidth = this.data.noDynamicFieldWidth;
    if (!this.parentData.hideOnLoad) {
      document.getElementById('filterModalOpenBtn').click();
    }
  }
  setTableConfig() {
    this.tableConfigData = {
      dtOptions: {
        maxRows: 10,
        searchButton: true,
        columnSearch: false,
        displayOptions: true,
        headerLabel: 'name',
        rowselect: {
          key: 'selected',
          // headerLabel: 'name',
          selectAll: false
        },
        showSelectAll: true
      }
    };
  }
  setDefaultOptions() {
    const temp: ModalFilterData = {
      headerClass: '',
      headerTitle: '',
      width: '1080',
      footerClass: '',
      formData: [],
      showModalClose: true,
      inputData: {},
      buttons: [],
      fadeType: 'left',
      staticFields: []
    };
    this.data = Object.assign(temp, this.data);
  }
  onModalClose() {
    this.modalService.setToggleModal(false);
  }
  btnAction(key) {
    this.layoutService.setDateFlag(true);
    this.parentData.formSubmitted = true;
    // form validation
    if (this.sharedService.getDisabledStatus(this.parentData.error_cls)) {
      return true;
    } else {
      if (this.tableConfigData.tableData) {
        this.data.inputData.selectedParams = this.tableConfigData.tableData.bodyContent.filter(
          ele => ele.selected
        );
        if (this.data.inputData.selectedParams.length === 0) {
          this.alert.open('info', 'message', 'Please Select Atleast One Row');
          return true;
        }
      }
      // dynamic saved data
      let formData = JSON.parse(JSON.stringify(this.data.inputData));
      // combining dynamic and static Filters
      formData = Object.assign(this.modalService.getAppliedStaticFilters(), formData);
      // preserving filters
      this.modalService.setAppliedStaticFilters(formData);
      const combinedForm = [...this.staticForm, ...this.data.formData];
      this.modalService.setFiltersData({ data: formData, fields: combinedForm });
      let payLoad: any = {};
      // combinedForm.forEach(ele => {
      combinedForm.filter(ele => !ele.hidden).forEach(ele => {
        payLoad[ele.key] = formData[ele.key];
      });
      if (this.data.wholeFilters) {
        payLoad.wholeFilters = formData;
      }
      try {
        if (this.data.dateFormat && payLoad.date) {
          payLoad = Object.assign(this.sharedService[this.data.dateFormat](payLoad.date), payLoad);
        } else {
          payLoad = Object.assign(this.sharedService.formatFromAndToDates(payLoad.date), payLoad);
        }
      } catch (error) {
        Config.logger(error);
      }
      if (this.tableConfigData.tableData) {
        payLoad.selectedParams = this.data.inputData.selectedParams;
      }
      this.modalAction.emit({ button: key, payLoad });
      if (this.data.inputData.siteId) {
        this.storage.api.local.save('siteId', this.data.inputData.siteId);
      }
    }
  }
  selectChange(event) {
    if (event.field.loadTable) {
      this.getFilterData(event.value);
    }
    this.selectFieldChanges.emit(event);
  }
  getFilterData(siteId) {
    const payLoad = {
      siteId,
      avgFlag: this.data.filterFlag
    };
    try {
      this.http.others.getTableDataBasedOnSite(payLoad).subscribe(response => {
        if (response.status) {
          this.tableConfigData.tableData = response.data.tableData;
        } else {
          this.sharedService.serviceErrorMessage(response.message);
        }
      });
    } catch (error) {
      Config.logger(error);
    }
  }
}
