import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EChartOption } from 'echarts';
import { Config } from 'src/app/config/config';
import { CommonServiceLayerService } from 'src/app/services/common-service-layer.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-aqi-portal-data',
  templateUrl: './aqi-portal-data.component.html',
  styleUrls: ['./aqi-portal-data.component.scss']
})
export class AqiPortalDataComponent implements OnInit {

  public loginLogo = { logo: '', title: '' };
  public lat: any;
  public lng: any;
  public geoJsonObject: any;
  public markers: any = [];
  public markersList: any;
  public selectedStation: any;
  public chartData: any;
  public stationsList: any;
  public xaxisData: any;
  public tableChartOptions: EChartOption = {};
  public infoTableData: any = [];
  constructor(
    private http: CommonServiceLayerService,
    private router: Router,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
    this.loadMap();
    this.getStateLogo();
    this.loadGeoJson();
    this.loadStation();
  }

  getStateLogo() {
    try {
      this.http.others.getStateMetaData().subscribe(
        response => {
          this.loginLogo = response[window.location.hostname] ? response[window.location.hostname] : response['default'];
          return this.loginLogo;
        });
    } catch (error) {
      Config.logger(error);
    }
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
  loadGeoJson() {
    try {
      this.http.anayticReports.loadStateGeoJson({ state: "Telangana" }).subscribe(response => {
        // this.http.anayticReports.loadGeoJson(this.landingPage).subscribe(response => {
        if (response.status) {
          this.lat = response.center.latitude ? response.center.latitude : 20.5937;
          this.lng = response.center.longitude ? response.center.longitude : 78.9629;
          this.geoJsonObject = response.geoJson;
        } else {
          this.sharedService.serviceErrorMessage(response.message);
        }
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: '#50a3ea', // feature.getProperty('color'),
      strokeWeight: 1,
      stroke: '#50a3ea'
    });
  }
  loadMap(event?) {
    try {
      this.http.others.aqiPortalData({ state: "Telangana" }).subscribe(response => {
        if (response.status) {
          this.markers = response['data'];
          this.markersList = response['data'];
          this.markers.forEach(element => {
            element.options = {
              color: (element.remarks === 'Satisfactory' || element.remarks === 'Moderate') ? 'black' : 'white',
              fontWeight: 'bold',
              fontSize: '0.6rem',
              text: '' + element['aqiData'],
            };
            const icon = {
              url: '',
              scaledSize: {
                width: 30,
                height: 30
              }
            };
            switch (element.remarks) {
              case 'Good':
                icon.url = 'assets/images/markers/value-good.png';
                break;
              case 'Satisfactory':
                icon.url = 'assets/images/markers/value-satisfactory.png';
                break;
              case 'Moderate':
                icon.url = 'assets/images/markers/value-moderate.png';
                break;
              case 'Poor':
                icon.url = 'assets/images/markers/value-poor.png';
                break;
              case 'Very Poor':
                icon.url = 'assets/images/markers/value-verypoor.png';
                break;
              case 'Severe':
                icon.url = 'assets/images/markers/value-severe.png';
                break;
              case 'NA':
                // if (this.layerType === 'WIND') {
                //   icon.url = 'assets/images/markers/arrow.png';
                // } else if (this.layerType === 'BARPRESS' || this.layerType === 'SWDR') {
                //   icon.url = 'assets/images/markers/value-moderate.png';
                // } else {
                //   icon.url = 'assets/images/markers/value-NA.png';
                // }
                icon.url = 'assets/images/markers/value-NA.png';
                break;
              default:
                icon.url = 'assets/images/markers/value-good.png';
                break;
            }
            element.icon = icon;
            this.loadMarker();
          });
          this.getTableData();
        } else {
          this.sharedService.serviceErrorMessage(response.message);
        }
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  loadMarker() {
    this.markers = [];
    this.markersList.forEach(element => {
      this.markers.push(element);
    });
  }
  mapMarkerClick(marker) {
    try {
      this.selectedStation = marker['stationId'];
      this.fetchStationData(marker['stationId']);
    } catch (error) {
      console.log(error);
    }
  }
  onMouseOver(infoWindow) {
    infoWindow.open();
  }
  onMouseOut(infoWindow) {
    infoWindow.close();
  }
  loadStation() {
    try {
      this.http.others.publicDropDownData({ state: "Telangana" }).subscribe(response => {
        if (response['status']) {
          this.stationsList = response['data'];
          if (this.stationsList.length > 0) {
            this.selectedStation = this.stationsList[0]['key']
            this.fetchStationData(this.selectedStation);
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
  fetchStationData(id) {
    try {
      this.chartData = undefined;
      const payLoad = {
        stationId: id,
        state: "Telangana"
      }
      this.http.others.aqiPortalDataChart(payLoad).subscribe(response => {
        if (response['status']) {
          this.chartData = response['data'];
          this.xaxisData = this.chartData['xaxisData'];
          for (let index = 0; index < this.chartData['chartData'].length; ++index) {
            this.chartData['chartData'][index]['options'] = this.setTableChartData(this.chartData['chartData'][index]);
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
  setTableChartData(seriesData) {
    try {
      this.tableChartOptions = {
        tooltip: {
          show: true,
          trigger: 'item',
          backgroundColor: 'white',
          textStyle: {
            fontSize: 15,
            color: 'black'
          },
          formatter: (pa) => pa['value']
        },
        grid: {
          height: 70,
          bottom: '2%',
          top: '10%'
        },
        xAxis: {
          type: 'category',
          show: true,
          data: this.xaxisData,
          axisLine: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          show: true,
          axisLine: {
            show: false
          }
        },
        series: [
          {
            data: seriesData,
            type: 'bar'
          }
        ]
      }
      return this.tableChartOptions;
    } catch (error) {
      console.log(error);
    }
  }
  getTableData() {
    try {
      this.http.others.aqiInfotable({}).subscribe(response => {
        if (response.status) {
          this.infoTableData = response.data;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
}
