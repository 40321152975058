import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { SharedModule } from '../shared.module';
import { TableModule } from '../table/table.module';


@NgModule({
  declarations: [FilterModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    TableModule
  ],
  exports: [FilterModalComponent]
})
export class SharedFilterModalModule { }
