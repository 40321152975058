import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-display',
  templateUrl: './list-display.component.html',
  styleUrls: ['./list-display.component.scss']
})
export class ListDisplayComponent implements OnInit {
@Input() data;
  constructor() { }

  ngOnInit() {
    // Config.logger(this.data);
  }

}
